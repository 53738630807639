import { Auth } from "@aws-amplify/auth";


const AuthProvider = {

    getPermissions: async () => {
        const permissions = localStorage.getItem('permissions');
        
        if (permissions !== null) return permissions;

        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();
        console.log("accessToken: ", accessToken);

        const cognitoGroups = accessToken.decodePayload()["cognito:groups"];
        localStorage.setItem("permissions", cognitoGroups);
        console.log("cognitoGroups: ", cognitoGroups);
        
        // TODO: receive userConfiguration from the token
        const userConfiguration = "datev_konto:71000";
        console.log("userConfiguration: ", userConfiguration);
        localStorage.setItem("userConfiguration", userConfiguration);

        return cognitoGroups ? Promise.resolve(cognitoGroups) : Promise.reject();
    },
};

export default AuthProvider;
