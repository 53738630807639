import React, { useState, useEffect } from 'react';
import './App.css'
import './scss/style.scss';
import { HashRouter, Route, Switch } from 'react-router-dom';


// Amplify setup
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
import awsconfig from './amplify-config';
import Amplify from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import AuthProvider from './AuthProvider';


import Background from './assets/bg.jpg';
import ExpensesContext from './ExpensesContext';

Amplify.configure(awsconfig);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const signUpFields = [
  {
    type: "username",
    required: true
  },
  {
    type: "password",
    required: true,
  },
/*  
  {
    type: "given_name",
    label: "First Name *",
    placeholder: "First Name",
    required: true,
  },
  {
    type: "family_name",
    label: "Last Name *",
    placeholder: "Last Name",
    required: true,
  },
*/  
  {
    type: "email",
    required: true,
  },
];


function App() {

  const [authState, setAuthState] = useState();
  const [permissions, setPermissions] = useState();


  useEffect(() => {
    console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);
    console.log("process.env.REACT_APP_VERSION: ", process.env.REACT_APP_VERSION);

    const fetchPermissions = async () => {
      const permissions = localStorage.getItem('permissions');
      console.log("permissions: ", permissions);
      setPermissions(permissions);
      await AuthProvider.getPermissions();
    };

    return onAuthUIStateChange(newAuthState => {
      console.log('newAuthState: ', newAuthState);

      // 'signin' when signin screen appears
      if(newAuthState === 'signedin'){
        fetchPermissions();
      }

      setAuthState(newAuthState)
    });
  }, [])


    return (
    <ExpensesContext.Provider value={ permissions }>
      <div className="App" slot="sign-in" style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover'
        
      }}>   
        <AmplifyAuthenticator>   
        
          { authState === 'signedin'
          ? 
            <HashRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch>
              </React.Suspense>
            </HashRouter>
          
          : <>
            <AmplifySignIn headerText="Welcome to Reload Expenses!" slot="sign-in" />
            <AmplifySignUp headerText="Sign Up for Reload Expenses!" slot="sign-up" 
              formFields={signUpFields}/>
            </>    
          
        }
        
        </AmplifyAuthenticator> 
      </div>
      </ExpensesContext.Provider>      
  );
  
}

export default App;
