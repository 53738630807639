const awsConfig = {
    Auth: {
        identityPoolId: '', 
        region: 'eu-central-1', 
        userPoolId: 'eu-central-1_HxGBntcTX', 
        userPoolWebClientId: '15qtub0skgg8rmkou8gi3rb78c' //'f1ir8a932k3r739k1p6jif875' // example: '3k09ptd8kn8qk2hpk07qopr86'
    },
}

export default awsConfig;
